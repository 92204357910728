<template>
  <b-table-simple class="mt-3" responsive>
    <b-tbody>
      <b-tr v-if="institution && institution.show_ra_macro">
        <b-th colspan="10" rowspan="1" class="header-ecmacro">
          {{
            $getVisibleNames(
              "teaching.ramacro",
              false,
              "RA Macro"
            ).toUpperCase()
          }}
        </b-th>
      </b-tr>
      <b-tr v-if="institution && institution.show_ra_macro">
        <b-td v-if="oldMatter">
          <div class="wrapper">
            <section v-if="matterRAMacros">
              <SentenceContainer
                style="padding-left: 12px"
                :Sentence="matterRAMacros"
              >
              </SentenceContainer>
              <button-edit
                v-if="
                  matterRAMacros
                    ? allows_crud && oldMatter && !oldMatter.is_closed
                    : false
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'teaching.ramacro',
                    false,
                    'RA Macro'
                  )}`
                "
                v-can="'teaching.change_ramacro'"
                @click="$bvModal.show(`modal-edit-ra-macro-${oldMatter.id}`)"
              ></button-edit>
            </section>
            <div>
              <section v-if="!matterRAMacros && !allows_crud">
                Esta
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}
                no cuenta con un
                {{ $getVisibleNames("teaching.ramacro", false, "RA Macro") }}.
              </section>
              <button-add
                v-if="
                  !matterRAMacros
                    ? allows_crud && oldMatter && !oldMatter.is_closed
                    : false
                "
                v-b-tooltip.v-secondary="
                  `Crear ${$getVisibleNames(
                    'teaching.ramacro',
                    false,
                    'RA Macro'
                  )}`
                "
                v-can="'teaching.change_ramacro'"
                @click="$bvModal.show(`modal-new-ra-macro`)"
              ></button-add>
              <b-modal
                :id="`modal-new-ra-macro`"
                :title="`Crear ${$getVisibleNames(
                  'teaching.ramacro',
                  false,
                  'RA Macro'
                )}.`"
                size="lg"
                hide-footer
              >
                <RAMacroForm
                  :matter_id="oldMatter.id"
                  @created="$bvModal.hide(`modal-new-ra-macro`)"
                  @create="$bvModal.hide(`modal-new-ra-macro`)"
                ></RAMacroForm>
              </b-modal>
              <b-modal
                :id="`modal-edit-ra-macro-${oldMatter.id}`"
                :title="`Editar ${$getVisibleNames(
                  'teaching.ramacro',
                  false,
                  'RA Macro'
                )}.`"
                size="lg"
                hide-footer
              >
                <RAMacroForm
                  :matter_id="oldMatter.id"
                  :RAMacro="matterRAMacros"
                  @update="$bvModal.hide(`modal-edit-ra-macro-${oldMatter.id}`)"
                  @updated="
                    $bvModal.hide(`modal-edit-ra-macro-${oldMatter.id}`)
                  "
                ></RAMacroForm>
              </b-modal>
            </div>
          </div>
        </b-td>
      </b-tr>
      <b-tr
        v-if="
          institution &&
          institution.show_evaluation_criteria_macro &&
          (evaluationCriteriaMacroList.length > 0 || allows_crud)
        "
      >
        <b-th colspan="10" rowspan="1" class="th-ecmacro">
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamacro",
              true,
              "Criterios de Evaluación Macro"
            ).toUpperCase()
          }}
          <div class="study-unit-button-div">
            <span
              class="secondary-color p-1 rounded"
              v-if="oldMatter && !oldMatter.automatic_achievement_weighing"
            >
              Ponderación: {{ WeighingAvailable }}%
            </span>
            <template v-if="allows_crud && oldMatter && !oldMatter.is_closed">
              <div class="text-right">
                <button-edit
                  class="ml-2"
                  @click="$bvModal.show(`modal-evaluation-criteria-macro`)"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'teaching.evaluationcriteriamacro',
                      true,
                      'Criterios de Evaluación Macro'
                    )}`
                  "
                ></button-edit>

                <b-modal
                  :title="
                    $getVisibleNames(
                      'teaching.evaluationcriteriamacro',
                      true,
                      'Criterios de Evaluación Macro'
                    )
                  "
                  :id="`modal-evaluation-criteria-macro`"
                  hide-footer
                  size="lg"
                >
                  <EvaluationCriteriaMacro
                    :matter_id="oldMatter.id"
                  ></EvaluationCriteriaMacro>
                </b-modal>
              </div>
            </template>
          </div>
        </b-th>
      </b-tr>
      <!-- Criterios de Evaluación Macro -->
      <b-tr
        v-if="
          institution &&
          institution.show_evaluation_criteria_macro &&
          (evaluationCriteriaMacroList.length > 0 || allows_crud)
        "
        class="tr-ecmacro"
      >
        <b-td colspan="10" rowspan="1" class="td-ecmacro">
          <template v-if="evaluationCriteriaMacroList.length > 0">
            <b-table-simple small responsive table-class="table-ecmacro">
              <b-thead>
                <b-tr>
                  <b-th class="th-header m-0 p-0 w-25">
                    {{
                      $getVisibleNames(
                        "teaching.evaluationcriteriamacro",
                        false,
                        "Criterio de Evaluación Macro"
                      ).toUpperCase()
                    }}</b-th
                  >
                  <!-- <b-th class="th-header m-0 p-0">PONDERACIÓN</b-th> -->
                  <b-th class="th-header m-0 p-0 w-25">CONTENIDOS</b-th>
                  <b-th class="th-header m-0 p-0 w-25">{{
                    $getVisibleNames(
                      "mesh.methodologicalstrategy",
                      true,
                      "Estrategias Metodológicas"
                    ).toUpperCase()
                  }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="criteria in evaluationCriteriaMacroList"
                  :key="criteria.id"
                >
                  <b-td>
                    <div
                      class="criteria py-1 d-flex"
                      style="padding-left: 20px"
                    >
                      <div class="w-100 d-flex">
                        <span>{{ criteria.order }}</span>
                        <SentenceContainer class="w-100" :Sentence="criteria">
                        </SentenceContainer>
                      </div>
                      <div
                        v-if="
                          oldMatter && !oldMatter.automatic_achievement_weighing
                        "
                        class="w-25"
                      >
                        <span> {{ criteria.weighing }}% </span>
                      </div>
                    </div>
                  </b-td>
                  <!-- <td>
                      </td> -->
                  <b-td>
                    <ECMacroContent
                      :eCMacro_id="criteria.id"
                      :allows_crud="
                        allows_crud && oldMatter && !oldMatter.is_closed
                      "
                    ></ECMacroContent>
                  </b-td>
                  <b-td>
                    <MethodologicalStrategiesRow
                      v-if="oldMatter"
                      :eCMacro_id="criteria.id"
                      :allows_crud="
                        allows_crud && oldMatter && !oldMatter.is_closed
                      "
                      :matter_id="oldMatter.id"
                    >
                    </MethodologicalStrategiesRow>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </template>
          <section v-if="evaluationCriteriaMacroList.length == 0">
            Esta
            {{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}
            no cuenta con
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamacro",
                true,
                "Criterios de Evaluación Macro"
              )
            }}.
          </section>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "EvaluationCriteriaMacroComponent",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    RAMacroForm: () => import("@/components/mesh/Matrix2/RAMacroForm"),
    EvaluationCriteriaMacro: () => import("../EvaluationCriteriaMacro"),
    ECMacroContent: () => import("../ECMacroContent.vue"),
    MethodologicalStrategiesRow: () =>
      import("../MethodologicalStrategiesRow.vue"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      raMacros: names.RA_MACROS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
    }),
    matterRAMacros() {
      if (!this.oldMatter) return {};
      return this.raMacros.find((x) => x.matter == this.oldMatter.id);
    },
    evaluationCriteriaMacroList() {
      if (!this.oldMatter) return [];
      let list = this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.oldMatter.id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      return list;
    },
    WeighingAvailable() {
      let weighing = 0;
      this.evaluationCriteriaMacroList.forEach((element) => {
        weighing += element.weighing;
      });
      return weighing;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.header-ecmacro {
  color: black;
}
table {
  width: 100%;
  table-layout: auto;
}
.study-unit-button-div {
  float: right;
  padding-right: 1rem;
}
.criteria-container {
  display: flex;
  flex-direction: row;
}
.criteria {
  width: 100%;
  text-align: left;
  padding-left: 0.4em;
}
.criteria-weighing {
  width: 20%;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
}
.table-ecmacro th,
.table-ecmacro td {
  border-left: 1px !important;
  border-bottom: 1px !important;
}
.table-ecmacro th {
  border-top: 1px !important;
}
.table-ecmacro th:last-of-type,
.table-ecmacro td:last-of-type {
  border-right: none;
}
.tr-ecmacro {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  border: 1px solid black;
}
.th-ecmacro {
  border: none;
  border-inline: 1px solid black;
  color: black;
}
.td-ecmacro .table-responsive {
  margin-bottom: 0 !important;
}
.td-ecmacro {
  padding: 0 !important;
}
p {
  /* text-align: justify; */
  padding-left: 3px;
}
.wrapper {
  margin: 15px;
  padding: 15px;
}
</style>